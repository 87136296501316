<swal [allowEscapeKey]="false"
      [allowOutsideClick]="false"
      [showCloseButton]="false"
      [showConfirmButton]="false"
      [width]="'35rem'"
      icon="error">
  <div *swalPortal="undefined" class="text-center">
    <h3 class="text-danger mb-4" [ngSwitch]="lang">
      <ng-container *ngSwitchCase="'de'">
        Ein Fehler ist aufgetreten
      </ng-container>
      <ng-container *ngSwitchCase="'fr'">
        Une erreur s'est produite.
      </ng-container>
      <ng-container *ngSwitchCase="'cs'">
        Došlo k chybě.
      </ng-container>
      <ng-container *ngSwitchDefault>
        An error occurred.
      </ng-container>
    </h3>
    <p [ngSwitch]="lang">
      <ng-container *ngSwitchCase="'de'">
        Es ist ein Fehler aufgetreten. Bitte haben Sie Geduld, während wir versuchen die Verbindung erneut aufzubauen.
      </ng-container>
      <ng-container *ngSwitchCase="'fr'">
        Une erreur s'est produite. Veuillez patienter pendant que nous essayons de rétablir la connexion.
      </ng-container>
      <ng-container *ngSwitchCase="'cs'">
        Došlo k chybě. Prosím, buďte trpěliví, zatímco se snažíme obnovit spojení.
      </ng-container>
      <ng-container *ngSwitchDefault>
        An error has occurred. Please be patient while we try to re-establish the connection.
      </ng-container>
    </p>
    <h1 class="fa fa-spin fa-2x fa-circle-o-notch text-muted"></h1>
  </div>
</swal>
