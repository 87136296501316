import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, filter, switchMap, take, timer, zip } from 'rxjs';
import { EnvService } from '@shared/services/env.service';
import { TranslationService } from '@shared/services/translation.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '@environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UpdateService {

  public readonly isUpdateAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updatePolicy: 'instant' | 'onRouteChange' | 'prevent' = 'onRouteChange';

  constructor(private envService: EnvService,
              private translationService: TranslationService,
              private router: Router,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) platformId: string) {
    if (isPlatformBrowser(platformId)) {
      timer(3000, 30000)
        .pipe(filter(() => !this.isUpdateAvailable$.value && !!environment.apiUrl.length))
        .subscribe(() => this.checkForUpdate());
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(switchMap(() => {
        let rt = this.route;
        const routeData = [rt.data];
        while (rt.firstChild != null) {
          rt = rt.firstChild;
          if (rt.data) {
            routeData.push(rt.data);
          }
        }
        // console.indexSelected(routeData.map((rt) => (rt as any).value));
        return zip(routeData)
          .pipe(take(1))
          .pipe(map((res) => res.filter((r) => Object.keys(r).includes('updatePolicy'))));
      }))
      .subscribe((data) => this.updatePolicy = data.length ? data[0].updatePolicy! : 'onRouteChange');
  }

  private checkForUpdate(): void {
    const currentVersion = environment.version;
    this.envService.getEnvSettings()
      .pipe(filter((settings) => settings.version !== currentVersion))
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isUpdateAvailable$.next(true);
          if (this.updatePolicy === 'instant') {
            location.reload();
            return;
          }
          this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd && this.updatePolicy !== 'prevent'))
            .subscribe(() => location.reload());
        },
        error: (error) => console.log(error)
      });

  }
}
