import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { SwalComponent, SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'auex-bootstrap-error',
  templateUrl: './bootstrap-error.component.html',
  styleUrls: ['./bootstrap-error.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SweetAlert2Module, CommonModule]
})
export class BootstrapErrorComponent implements AfterViewInit {

  @ViewChild(SwalComponent) public swal?: SwalComponent;

  public lang: 'de' | 'en' | 'fr' | 'cs' = 'en';

  constructor(translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: string) {
    const userLang = translate.getBrowserCultureLang()?.split('-')[0];
    if (userLang && ['de', 'en', 'fr', 'cs'].includes(userLang.toLowerCase())) {
      // @ts-ignore (string !== 'de' | ...)
      this.lang = userLang;
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.swal?.fire();
    }
  }
}
