<ng-container *ngVar="loadingState$ | async as state">
  <router-outlet *ngIf="state === BootstrappingState.Success"></router-outlet>
  <div *ngIf="state === BootstrappingState.Loading" class="load-container h-100">
    <svg class="spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" fill="none" r="20" stroke-width="5"></circle>
    </svg>
  </div>
  <auex-bootstrap-error *ngIf="state === BootstrappingState.Error || state === BootstrappingState.SsrError"></auex-bootstrap-error>
</ng-container>

<!--used to improve performance for long lot-lists-->
<p-overlayPanel #myLotActionsPanel>
  <ng-container *ngIf="myLotActionsTemplate" [ngTemplateOutlet]="myLotActionsTemplate"></ng-container>
</p-overlayPanel>

