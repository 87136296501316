import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, forkJoin, MonoTypeOperatorFunction, Observable, of, retry, switchMap, tap, timer } from 'rxjs';
import { ConfigurationService } from '@shared/services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, map } from 'rxjs/operators';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { TranslationService } from '@shared/services/translation.service';
import { SessionService } from '@shared/services/session.service';
import { EnvService } from '@shared/services/env.service';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { initSentry } from '@shared/misc/sentry';

@Injectable({ providedIn: 'root' })
export class BootstrapGuard implements CanActivate {

  public readonly bootstrappingState$: BehaviorSubject<BootstrappingState> = new BehaviorSubject<BootstrappingState>(BootstrappingState.Loading);

  private readonly isServer: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,
              private sessionService: SessionService,
              private translationService: TranslationService,
              private configService: ConfigurationService,
              private envService: EnvService,
              private translate: TranslateService,
              private meta: Meta,
              @Inject(PLATFORM_ID) plattformId: string) {
    this.isServer = isPlatformServer(plattformId);
    this.bootstrap();

  }

  private bootstrap(): void {
    // getting config + session-info -> load language, build manifest file & load all categories
    this.envService.getEnvSettings()
      .pipe(this.retryRequest())
      .pipe(tap((env) => {
        // we initialize sentry for the server within server.ts
        if (!this.isServer && env.sentryDsn) {
          initSentry(env, 'Client');
        }
      }))
      .pipe(switchMap(() =>
        forkJoin([
          this.configService.getConfig(),
          (!this.isServer
            ? this.sessionService.getSessionInfoWithUserProfile(true).pipe(catchError(() => of(undefined)))
            : of(undefined)),
          this.configService.loadCustomCss()
        ])))
      .pipe(this.retryRequest())
      .pipe(switchMap((result) => {
        // get config + session-info
        const config = result[0]!;
        this.translate.addLangs(config.siteCultures.map(((sc) => sc.cultureName.toLowerCase())));
        // found culture in url? -> check if it exists and then use it
        const locationPathSegments = this.document.location.pathname.split('/');
        const urlCulture = locationPathSegments.length > 1 ? locationPathSegments[1].match('[a-zA-Z]{2}-[a-zA-Z]{2}') : undefined;
        if (urlCulture?.length && config.siteCultures.find((c) => c.cultureName.toLowerCase() === urlCulture[0].toLowerCase())) {
          return this.translate.use(urlCulture[0]);
        }
        // found session-info? get userprofile and use the saved language
        else if (result[1] && config.siteCultures.find((c) => c.cultureName === result[1]!.userProfile!.settings.cultureName)) {
          return this.translate.use(result[1]!.userProfile!.settings.cultureName);
        }
        // no session-info found? try to get lang from localstorage, otherwise from browser
        else {
          const langSelectedByUser = !this.isServer ? localStorage.getItem('auex_lang') : undefined;
          const navLang = this.translate.getBrowserCultureLang();

          let langToLoad;
          // case 1: user manually has set his language previously
          if (langSelectedByUser?.length && config.siteCultures.find((c) => c.cultureName === langSelectedByUser)) {
            langToLoad = langSelectedByUser;
          }
          // case 2: exact matching of available cultures && browser-culture
          else if (navLang && config.siteCultures.some((culture) => culture.cultureName.toLowerCase() === navLang.toLowerCase())) {
            langToLoad = navLang.toLowerCase();
          }
          // case 3: partial matching of existing cultures && browser-culture
          else {
            langToLoad = config.siteCultures.find((culture) => culture.cultureName.split('-')[0] === navLang?.split('-')[0])?.cultureName;
          }
          return this.translate.use(langToLoad ?? config.defaultSiteCultureName).pipe(this.retryRequest());
        }
      }))
      .pipe(this.retryRequest())
      .subscribe({
        next: (res) => {
          this.document.documentElement.lang = this.translate.currentLang;                                     // todo move to translationService.init?
          this.meta.updateTag({ 'http-equiv': 'content-language', content: this.translate.currentLang });   // todo move to translationService.init?
          this.translationService.init(res);
          this.configService.buildWebManifest(this.translationService.translation.shared.websiteName, this.translationService.translation.shared.websiteShortName);
          this.bootstrappingState$.next(BootstrappingState.Success);
        },
        error: (e) => {
          console.error(e);
          this.bootstrappingState$.next(!this.isServer ? BootstrappingState.Error : BootstrappingState.SsrError);
        }
      });
  }

  private retryRequest<T>(): MonoTypeOperatorFunction<T> {
    let retries = 0;
    return (src: Observable<T>) =>
      src.pipe(
        retry({
          count: !this.isServer ? undefined : 0, // don't retry when ssr is active
          delay: () => {
            let delayTime = 0;
            // first retry? no delay
            if (!retries) {
              retries++;
            }
            // second retry? delay 2-3 seconds
            else if (retries === 1) {
              // wait between 2 and 3 seconds before retrying
              this.bootstrappingState$.next(BootstrappingState.Error);
              delayTime = 2000 + Math.random() * 1000;
              retries++;
            }
            // third+ retry? delay 5-10 seconds
            else {
              // retry every 5 to 10 seconds
              delayTime = 5000 + Math.random() * 5000;
            }
            return timer(delayTime);
          }
        })
      );
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.bootstrappingState$
      .pipe(filter((s) => s === BootstrappingState.Success || s === BootstrappingState.SsrError))
      .pipe(map((s) => s === BootstrappingState.Success));
  }
}


export enum BootstrappingState {
  Loading = 'loading',
  Error = 'error',
  SsrError = 'ssr-error',
  Success = 'success',
}
