import { Inject, Injectable, makeStateKey, PLATFORM_ID, StateKey, TransferState } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@environment';
import { EnvSettings } from '@shared/models/env-settings';
import { HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class EnvService {

  private readonly stateKeyEnvValue: StateKey<EnvSettings> = makeStateKey('envValue');

  private readonly isServer: boolean;

  constructor(private transferState: TransferState,
              private http: HttpClient,
              @Inject(PLATFORM_ID) plattformId: string) {
    this.isServer = isPlatformServer(plattformId);
  }

  // TODO outsource to shared-package for frontend & backoffice (2 implementations = overhead & inconsistency) & decide whether to use the environment.ts OR a settings-property within this class
  // gets executed by client - execution in main-service should suffice - TODO remove from bootstrap-service
  public getEnvSettings(): Observable<EnvSettings> {
    const request = this.transferState.hasKey(this.stateKeyEnvValue)
      ? of(this.transferState.get(this.stateKeyEnvValue, null)!)
        .pipe(tap(() => this.transferState.remove(this.stateKeyEnvValue)))
      : this.fetchEnvVariables(this.isServer)
        .pipe(tap((result) => this.isServer && this.transferState.set(this.stateKeyEnvValue, result)));

    return request
      .pipe(tap((settings) => this.handleEnvResponse(settings)));
  }

  private fetchEnvVariables(isServer: boolean): Observable<EnvSettings> {
    return this.http.get<EnvSettings>((isServer ? `http://localhost:${process.env['PORT'] ?? 4000}` : '') + `/assets/env.json?rnd2=${Math.random()}${isServer ? '&server' : ''}`)
      .pipe(tap((result) => this.handleEnvResponse(result as EnvSettings)))
      .pipe(catchError((error) => {
        const contentType = error.headers?.get('content-type');
        console.log(error);
        if (!error.ok) {
          console.error(`HTTP error! status: ${error.status}`);
        } else if (!contentType || !contentType.includes('application/json')) {
          console.error('fetched env.json received as' + contentType);
        }

        throw new Error(error);
      }));
  }

  private handleEnvResponse(settings: EnvSettings): void {
    environment.apiUrl = stripTrailingSlash(settings.apiUrl);
    environment.baseUrl = stripTrailingSlash(settings.baseUrl);
    environment.version = settings.version;
  }


}


function stripTrailingSlash(url: string): string {
  return url.endsWith('/') ? url.slice(0, url.length - 1) : url;
}
