import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent, VarDirective } from 'inova-shared';
import { ConfigurationService } from '@shared/services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { Observable, take } from 'rxjs';
import { UpdateService } from '@shared/services/update.service';
import { BootstrapGuard, BootstrappingState } from './pages/bootstrap-guard.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { BootstrapErrorComponent } from './components/bootstrap-error/bootstrap-error.component';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'auex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [VarDirective, NgIf, RouterOutlet, BootstrapErrorComponent, OverlayPanelModule, NgTemplateOutlet, AsyncPipe]
})
export class AppComponent extends BaseComponent implements OnInit {

  @ViewChild('myLotActionsPanel') public myLotActionsOverlayPanel?: OverlayPanel;
  public myLotActionsTemplate?: TemplateRef<unknown>;

  public readonly loadingState$: Observable<BootstrappingState>;
  public readonly BootstrappingState: typeof BootstrappingState = BootstrappingState;

  constructor(updateService: UpdateService,                   // inject to init,
              private bootstrapGuard: BootstrapGuard,         // inject to init,
              private configService: ConfigurationService,
              private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private plattformId: string) {
    super();
    this.loadingState$ = this.bootstrapGuard.bootstrappingState$.asObservable();
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(switchMap(() => this.loadingState$))
      .pipe(filter((v) => v === BootstrappingState.Success))
      .subscribe(() => {
        const routerUrl = this.router.url;
        if (routerUrl.split('?')[0] === '/') {
          this.router.navigate([this.translate.currentLang], {
            queryParams: this.route.snapshot.queryParams,
            replaceUrl: true
          });
        } else if (routerUrl.split('/').length > 2 &&
          !routerUrl.includes('/sixbid-livebidding')
          && !routerUrl.includes('/floorview')
          && !routerUrl.includes('/iframes')  // TODO remove when sixbid got updated
          && !routerUrl.includes('/bieten')   // TODO remove when sixbid got updated
          && !routerUrl.includes('/pager')) { // TODO remove when sixbid got updated
          this.fixCulture();
        }
      });
  }

  private fixCulture(): void {
    this.loadingState$
      .pipe(filter((v) => v === BootstrappingState.Success))
      .pipe(take(1))
      .subscribe(() => {
        const url = this.router.url.split('/');
        if (url.length > 2 && !this.configService.configuration!.siteCultures.some((sc) => sc.cultureName === url[1])) {
          url[1] = this.translate.currentLang;
          this.router.navigateByUrl(url.join('/'), { replaceUrl: true });
        }
      });
  }
}
