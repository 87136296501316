import { EnvSettings } from '@shared/models/env-settings';
import * as Sentry from '@sentry/angular';
import { environment } from '@environment';

// TODO outsource to ng-shared
export function initSentry(settings: Pick<EnvSettings, 'sentryDsn' | 'customerTag' | 'version'>, applicationType: 'Client' | 'SSR'): void {
  if (!settings.sentryDsn?.length) {
    console.log(`Skipping sentry init: production: ${environment.production}, sentryDSN: ${settings.sentryDsn}`);
    return;
  }
  if (Sentry.isInitialized()) {
    console.log('Sentry already initialized. Skipping initialization.');
    return;
  }
  console.log(`Initializing sentry : sentryDSN: ${settings.sentryDsn}, customerTag: ${settings.customerTag}, applicationType: ${applicationType}`);
  Sentry.init({
    dsn: settings.sentryDsn,
    environment: settings.customerTag,
    release: settings.version,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    integrations: [
      ...(applicationType === 'Client' ? [Sentry.browserTracingIntegration({ enableHTTPTimings: true })] : []),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      /*  TODO - future stuff
           new Sentry.Replay({
            blockAllMedia: true,
            maskAllInputs: true,
            maskAllText: false,
            unmask: ['auex-bidding-input', 'input-checkbox']
            /!* capture specific events only: https://docs.sentry.io/platforms/javascript/guides/angular/session-replay/privacy#example-capturing-500-status-codes-only
             beforeAddRecordingEvent *!/
          }), */
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [400, [402, 599]], failedRequestTargets: [/.*/] }) // ignore 401
    ],
    attachStacktrace: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay TODO
    // replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event) => {
      if (event.user) {
        delete event.user.ip_address;
      }
      return event;
    }
  });
  Sentry.setTag('application.type', applicationType);
}
